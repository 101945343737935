<template>
	<div class="container-form">
		<template v-if="loadedProc && loadedObj">
			<div class="form-tbf">
				<div class="notifications-list crud-page">
					<template>
						<div
							class="notification-row level-1"
							v-bind:class="{ active: selectedNotificationsOrg.some((element) => mail_notifications.includes(element)) }"
							@click="toggleSelected('selectedNotificationsOrg', 'task_comment')"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t("settings-notifications.comment_notifications") }}</span>
							</div>
						</div>
					</template>

					<!-- Notificaitons Objectives -->

					<template v-if="soft_objectives.is_active">
						<div
							class="notification-row level-1"
							v-bind:class="{ active: selectedNotificationsObj.some((element) => objectiveNotifications.includes(element)) }"
							@click="selectAllObjectives"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t("settings-notifications.objectives") }}</span>
							</div>
						</div>
						<div
							class="notification-row level-2"
							v-for="notification in objectiveNotifications"
							v-bind:class="{ active: selectedNotificationsObj.includes(notification) }"
							@click="toggleSelected('selectedNotificationsObj', notification)"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>
					</template>

					<template v-if="soft_procedures.is_active">
						<div
							class="notification-row level-1"
							v-bind:class="{ active: selectedNotificationsPro.some((element) => proceduresNotifications.includes(element)) }"
							@click="selectAllProcedures"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t("settings-notifications.procedures") }}</span>
							</div>
						</div>
						<div
							class="notification-row level-2"
							v-for="notification in proceduresNotifications"
							v-bind:class="{ active: selectedNotificationsPro.includes(notification) }"
							@click="toggleSelected('selectedNotificationsPro', notification)"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>

						<div
							class="notification-row level-1"
							v-bind:class="{ active: selectedNotificationsPro.some((element) => processesNotifications.includes(element)) }"
							@click="selectAllProcesses"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t("settings-notifications.processes") }}</span>
							</div>
						</div>
						<div
							class="notification-row level-2"
							v-for="notification in processesNotifications"
							v-bind:class="{ active: selectedNotificationsPro.includes(notification) }"
							@click="toggleSelected('selectedNotificationsPro', notification)"
						>
							<div class="checkbox">
								<div class="checkmark"></div>
								<span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="form-submit">
				<div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
				<div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>

				<div class="actions-steps">
					<button class="btn-tbf white only-icon prev" @click="changeStepFunction('prevStep')">
						<div class="icon"><icon-arrow /></div>
					</button>
					<button class="btn-tbf white only-icon" @click="changeStepFunction('nextStep')" v-if="soft_organigram">
						<div class="icon"><icon-arrow /></div>
					</button>
				</div>

				<div class="action-form">
					<button id="buttonSubmit" class="btn-tbf blue" @click="saveAction">
						<div class="loader-spin" v-if="loadedSubmit"></div>
						<span class="text" v-else>{{ $t("general.update") }}</span>
					</button>
				</div>
			</div>
		</template>
		<LoaderStepNotifications v-else />
	</div>
</template>

<script>
import IconClose from "@/components/Icons/Close";
import IconArrow from "@/components/Icons/AngleRight";
import LoaderStepNotifications from "@/components/PagesLoaders/CreateUserStepNotifications";

export default {
	components: {
		IconClose,
		IconArrow,
		LoaderStepNotifications,
	},
	props: {
		user: Object,
	},
	computed: {
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
		soft_procedures() {
			return this.$store.getters["applications/getApplication"]("Proceduri");
		},
		soft_objectives() {
			return this.$store.getters["applications/getApplication"]("Obiective");
		},
	},
	data() {
		return {
			loaded: false,
			loadedProc: false,
			loadedObj: false,
			loadedSubmit: false,
			error_message: "",
			success_message: "",
			errorsBe: [],
			initialData: '{ "selectedNotificationsPro": "", "selectedNotificationsObj": "", "selectedNotificationsOrg": "" }',
			selectedNotificationsPro: [],
			proceduresNotifications: [
				"new_procedure",
				"assigned_to_procedure",
				"edit_live_procedure",
				"change_to_draft_procedure",
				"change_to_live_procedure",
				"delete_procedure",
				"add_improvement_proposal_to_procedure",
				"change_status_improvement_proposal",
				"create_admin_procedures",
			],
			processesNotifications: ["new_version_process", "assigned_to_process", "edit_live_process", "add_improvement_proposal_to_process", "delete_process", "create_admin_processes"],
			selectedNotificationsObj: [],
			objectiveNotifications: ["net_set_promise_mail", "next_day_assigment_mail", "report_mail"],
			mail_notifications: ["task_comment"],
			selectedNotificationsOrg: [],
		};
	},
	beforeDestroy() {
		this.$root.$off("user_form_change_step");
	},
	async mounted() {
		if (this.soft_procedures.is_active && this.$route.params.slug) {
			this.getProcedureData();
		} else {
			this.loadedProc = true;
		}
		if (this.soft_objectives.is_active && this.$route.params.slug) {
			this.getObjectiveData();
		} else {
			this.loadedObj = true;
		}

		if (this.$parent.userData.mail_notifications.length) {
			this.selectedNotificationsOrg = this.$parent.userData.mail_notifications;
		}

		this.$root.$on("user_form_change_step", (modeSave, changeData) => {
			if (modeSave == "save") {
				this.saveAction(changeData);
			} else {
				if (!changeData.step) {
					this.$emit(changeData.direction);
				} else {
					this.$emit("goToStep", changeData.step);
				}
			}
		});
	},
	methods: {
		showModal(type, data = false) {
			this.$root.$emit("open_modal", type, data);
		},
		async saveAction(changeStep = false, withRedirect = false) {
			var btnSubmit = document.getElementById("buttonSubmit");
			btnSubmit.disabled = true;
			this.loadedSubmit = true;

			this.error_message = "";

			let objDataOrg = {};
			objDataOrg.mail_notifications = this.selectedNotificationsOrg;

			await this.updateUserOrg(objDataOrg);

			if (this.soft_objectives.is_active) {
				// Object form data for objective
				let objDataObjectives = {};
				objDataObjectives.net_set_promise_mail = this.selectedNotificationsObj.includes("net_set_promise_mail") ? 1 : 0;
				objDataObjectives.next_day_assigment_mail = this.selectedNotificationsObj.includes("next_day_assigment_mail") ? 1 : 0;
				objDataObjectives.report_mail = this.selectedNotificationsObj.includes("report_mail") ? 1 : 0;

				await this.updateUserObjectives(objDataObjectives);
			}

			if (this.soft_procedures.is_active) {
				// Object form data for procedures
				let objDataProcedures = {};
				objDataProcedures.mail_notifications = this.selectedNotificationsPro;

				await this.updateUserProcedures(objDataProcedures, changeStep, withRedirect);
			}
		},
		updateUserProcedures(objData, changeStep, withRedirect) {
			axios
				.patch(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.$route.params.slug}`, objData)
				.then(({ data }) => {
					this.loadedSubmit = false;
					btnSubmit.disabled = false;

					this.success_message = this.$t("create-user.success_update_msg");
					setTimeout(() => {
						this.success_message = "";
					}, 3000);

					if (changeStep) {
						this.$nextTick(() => {
							if (!changeStep.step) {
								this.$emit(changeStep.direction);
							} else {
								this.$emit("goToStep", changeStep.step);
							}
						});
					}

					if (withRedirect) {
						// if(this.selectedVacantJob) {
						// this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
						// } else {
						if (this.$route.query.department_id) {
							this.$router.push({ name: "company" });
						} else {
							this.$router.push({ name: "user-show", params: { slug: data.data.slug } });
						}
						// }
					}
				})
				.catch((error) => {
					this.errorsBe = error.response.data.errors;
					this.loadedSubmit = false;
					btnSubmit.disabled = false;

					setTimeout(() => {
						if (error.response.status == 500) {
							this.error_message = this.$t("error.500");
						}
					}, 300);
				})
				.finally(() => {
					var currentData = JSON.parse(this.initialData);
					currentData.selectedNotificationsPro = this.selectedNotificationsPro;

					this.initialData = JSON.stringify(currentData);
				});
		},
		updateUserObjectives(objData) {
			axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$route.params.slug}`, objData).then(() => {
				var currentData = JSON.parse(this.initialData);
				currentData.selectedNotificationsObj = this.selectedNotificationsObj;

				this.initialData = JSON.stringify(currentData);
			});
		},
		updateUserOrg(objData) {
			// console.log('')
			axios
				.patch(`/users/${this.$route.params.slug}`, objData)
				.then(() => {
					var currentData = JSON.parse(this.initialData);
					currentData.selectedNotificationsOrg = this.selectedNotificationsOrg;

					this.initialData = JSON.stringify(currentData);
				})
				.finally(() => {
					this.$parent.getUserData();
				});
		},
		changeStepFunction(direction, stepNo = false) {
			// Create a JSon stringify with current data form
			var currentObject = JSON.stringify({
				selectedNotificationsPro: this.selectedNotificationsPro,
				selectedNotificationsObj: this.selectedNotificationsObj,
			});

			// Check diference between initial data and current
			if (this.initialData === currentObject || !this.loaded) {
				if (direction) {
					this.$emit(direction);
				} else if (stepNo) {
					this.$emit("goToStep", stepNo);
				}
			} else {
				this.$root.$emit("open_modal", "confirm_change_step", { direction: direction, step: stepNo });
			}
		},
		getProcedureData() {
			axios
				.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.$route.params.slug}/edit`)
				.then(({ data }) => {
					this.selectedNotificationsPro = data.data.mail_notifications;
				})
				.finally(() => {
					var currentData = typeof this.initialData == "string" ? JSON.parse(this.initialData) : this.initialData;
					currentData.selectedNotificationsPro = this.selectedNotificationsPro;

					this.initialData = JSON.stringify(currentData);
					this.loadedProc = true;
				});
		},
		getObjectiveData() {
			axios
				.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$route.params.slug}/edit`)
				.then(({ data }) => {
					if (data.data.net_set_promise_mail) {
						this.selectedNotificationsObj.push("net_set_promise_mail");
					}
					if (data.data.next_day_assigment_mail) {
						this.selectedNotificationsObj.push("next_day_assigment_mail");
					}
					if (data.data.report_mail) {
						this.selectedNotificationsObj.push("report_mail");
					}
				})
				.finally(() => {
					var currentData = typeof this.initialData == "string" ? JSON.parse(this.initialData) : this.initialData;
					currentData.selectedNotificationsObj = this.selectedNotificationsObj;

					this.initialData = JSON.stringify(currentData);
					this.loadedObj = true;
				});
		},
		toggleSelected(filter, type) {
			if (this[filter].includes(type)) {
				this[filter].splice(this[filter].indexOf(type), 1);
			} else {
				this[filter].push(type);
			}
		},
		selectAllProcedures() {
			if (this.selectedNotificationsPro.some((element) => this.proceduresNotifications.includes(element))) {
				this.proceduresNotifications.map((el) => {
					if (this.selectedNotificationsPro.includes(el)) {
						this.selectedNotificationsPro.splice(this.selectedNotificationsPro.indexOf(el), 1);
					}
				});
			} else {
				this.proceduresNotifications.map((el) => {
					this.selectedNotificationsPro.push(el);
				});
			}
		},
		selectAllProcesses() {
			if (this.selectedNotificationsPro.some((element) => this.processesNotifications.includes(element))) {
				this.processesNotifications.map((el) => {
					if (this.selectedNotificationsPro.includes(el)) {
						this.selectedNotificationsPro.splice(this.selectedNotificationsPro.indexOf(el), 1);
					}
				});
			} else {
				this.processesNotifications.map((el) => {
					this.selectedNotificationsPro.push(el);
				});
			}
		},
		selectAllObjectives() {
			if (this.selectedNotificationsObj.some((element) => this.objectiveNotifications.includes(element))) {
				this.objectiveNotifications.map((el) => {
					if (this.selectedNotificationsObj.includes(el)) {
						this.selectedNotificationsObj.splice(this.selectedNotificationsObj.indexOf(el), 1);
					}
				});
			} else {
				this.objectiveNotifications.map((el) => {
					this.selectedNotificationsObj.push(el);
				});
			}
		},
	},
};
</script>
